<template>
 <div>
  <svg viewBox="0 0 183 183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="userSpaceOnUse" id="Naamloos_verloop_8" x1="136.3" x2="58.79" y1="57.8" y2="122.83">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g data-name="Laag 2" id="Laag_2">
    <g data-name="Laag 1" id="Laag_1-2">
     <rect :fill="background" height="183" width="183"/>
     <path d="M87.33,58.41a17.53,17.53,0,0,0-10.46-3.55,12.65,12.65,0,0,1,25,.45,17.23,17.23,0,0,0-4-.45A17.54,17.54,0,0,0,87.33,58.41Zm2,13.67h0Zm0,0h0ZM77,59.87h-.32a12.14,12.14,0,0,0-2.19.2,11.57,11.57,0,0,0-2.42.68,12.62,12.62,0,0,0-8,10.29H80.35a14.27,14.27,0,0,1,.23-1.74,16.17,16.17,0,0,1,.7-2.61A17.5,17.5,0,0,1,83.76,62,12.62,12.62,0,0,0,77,59.87ZM89.37,72.08h0Zm0,0h0ZM106,62.77a13.76,13.76,0,0,0-2.16-1.44,12.8,12.8,0,0,0-2.33-.95A12.65,12.65,0,0,0,90.9,62a12.61,12.61,0,0,0-5.14,7.07,11.62,11.62,0,0,0-.4,2.05,17.24,17.24,0,0,1,4,1h0a16.28,16.28,0,0,1,2.47,1.15c.48.28,1,.58,1.41.9l.25.18a2.75,2.75,0,0,1,.26.22,5.22,5.22,0,0,1,.43.39,9.63,9.63,0,0,1,2.69,9.66,2.8,2.8,0,0,0-.09.53,2.25,2.25,0,0,0,.45,1.75,3.13,3.13,0,0,0,1,.8,4.22,4.22,0,0,0,1.72.51,3.26,3.26,0,0,0,2.1-1.08.3.3,0,0,0,.08-.08,3.38,3.38,0,0,0,.79-2.45,2.76,2.76,0,0,0-.06-.4l-.06-.25-.11-.37c-.58-2-1.66-5.66,1.51-9l.06-.06a10.94,10.94,0,0,1,6.25-3.23A12.69,12.69,0,0,0,106,62.77ZM89.37,72.08h0Zm25.92,4h-.63c-.73,0-1.37,0-2,.06a9,9,0,0,0-2.76.55,5.79,5.79,0,0,0-2.08,1.43,2.81,2.81,0,0,0-.59,3c.07.32.17.66.28,1l.12.41a8.21,8.21,0,0,1,.27,1.38c0,.08,0,.15,0,.23a8.46,8.46,0,0,1-2,6.09,8.37,8.37,0,0,1-5.69,2.93h-.21A9,9,0,0,1,93.38,90a5.1,5.1,0,0,1-.36-.51,6.9,6.9,0,0,1-1.12-3A8.53,8.53,0,0,1,92,83.69c0-.12,0-.24.08-.36a4.92,4.92,0,0,0,.1-2.25,4.53,4.53,0,0,0-1.49-2.62,1.45,1.45,0,0,0-.19-.17,11.08,11.08,0,0,0-2-1.16,11.28,11.28,0,0,0-2.67-.87A11.13,11.13,0,0,0,83.23,76H56.55V89a30,30,0,0,0,29.81,30v10.11H84.23a10,10,0,0,0-10,10v2.12h30.32v-2.12a10,10,0,0,0-10-10H92.42V119a30,30,0,0,0,29.81-30V76C121.15,76,119,76,115.29,76Zm-6.7-20.68,4.29,4.29,13.57-13.58-4.28-4.29ZM89.37,72.08h0Z" id="icon-dessert" style="fill:url(#Naamloos_verloop_8);"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>